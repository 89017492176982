import server from "./axios.config.js";

export const getCropList = function() {
    return server({
        method: "get",
        url: "http://110.87.103.59:18086/commonservice/dict/getList?typeCode=irrigation"
    });
};
export const getBreedList = function() {
    return server({
        method: "get",
        url: "http://110.87.103.59:18086/commonservice/dict/getList?typeCode=irrigation"
    });
};
export const postSeveframlandList = function(
    // name,
    // area,
    // linkman,
    // linkmanPhone,
    // province,
    // city,
    // county,
    // irrigationId,
    // image
    param
) {
    return server({
        method: "post",
        url: "http://110.87.103.59:18086/farmfriend/land/save",
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: param
    });
};
export const getEditFarmland = function(id) {
    return server({
        method: "get",
        url: `http://110.87.103.59:18086/farmfriend/land/getById?id=${id}`
    });
};
export const postFarmland = function(
    name,
    area,
    linkman,
    linkmanPhone,
    province,
    city,
    county,
    cropsId,
    seasonId,
    breedId,
    irrigationId,
    id
) {
    return server({
        method: "post",
        url: "http://110.87.103.59:18086/farmfriend/land/update",
        data: {
            name,
            area,
            linkman,
            linkman_phone: linkmanPhone,
            province,
            city,
            county,
            crop_id: cropsId,
            season_id: seasonId,
            crop_type_id: breedId,
            irrigation: irrigationId,
            id
        }
    });
};

export const getHistory = function(id) {
    return server({
        method: "get",
        url: `http://110.87.103.59:18086/farmfriend/landCrop/selectPlantHistory?land_id=${id}`
    });
};

export const getHistoryList = function(id) {
    return server({
        method: "get",
        url: `http://110.87.103.59:18086/farmfriend/phenologyoperrec/getHistoryList?landCropId=${id}`
    });
};

export const getHistoryTop = function(id) {
    return server({
        method: "get",
        url: `http://110.87.103.59:18086/farmfriend/landCrop/getById?id=${id}`
    });
};
// 获取位置信息1
export const getLocation = function(lonlat) {
    return server({
        method: "get",
        url: `http://110.87.103.59:18086/commonservice/util/getLocation?lonlat=${lonlat}`
    });
};

export const postUpData = function(param) {
    return server({
        method: "post",
        url: "http://110.87.103.59:18086/farmfriend/land/update",
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: param
    })
}

export const selectList = function() {
    return server({
        method: "get",
        url: "http://110.87.103.59:18086/farmfriend/land/selectList"
    })
}

//  根据权限查询首页待办
export const landToDo = function() {
    return server({
        url: "http://110.87.103.59:18086/farmfriend/landCrop/todo"
    })
}